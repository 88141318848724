import React from 'react'

function Plaix(props) {
    return (
        <a href="/" className="dev">
            Создано в <span className="color">PlaiX</span>
        </a>
    )
}

export default Plaix
