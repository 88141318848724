import { createSlice } from '@reduxjs/toolkit';

const categorySlice = createSlice({
  name: 'category',
  initialState: null, // Исходное состояние - null
  reducers: {
    setCategory: (state, action) => action.payload, // Установка текущей категории
  },
});

export const { setCategory } = categorySlice.actions;
export default categorySlice.reducer;
